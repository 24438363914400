var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var FK,HK,JK,MK,PK;$CLJS.AK=function(a){return $CLJS.Hh($CLJS.q($CLJS.fB),a,$CLJS.rF)};$CLJS.BK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.CK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.DK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.EK=new $CLJS.M(null,"object-id","object-id",-754527291);FK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.GK=new $CLJS.M(null,"operators","operators",-2064102509);HK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.IK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);JK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.KK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.LK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
MK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.NK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.OK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);PK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.QK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.RK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(HK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.eD],null)],null)],null));$CLJS.Y(PK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,HK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.DD],null)],null)],null));$CLJS.Y(JK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.nF],null),PK,$CLJS.tD],null));
$CLJS.Y(MK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.nF],null),HK,$CLJS.CD],null));
$CLJS.NF.g($CLJS.nF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.nF],null),HK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.CD,$CLJS.tD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.vi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.WA(a)):null},$CLJS.us,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,MK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,JK],null)],null)],null));$CLJS.tE($CLJS.nF,$CLJS.rF);$CLJS.OD.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});$CLJS.AE($CLJS.qA,$CLJS.H([$CLJS.tD]));$CLJS.OD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});
$CLJS.tE($CLJS.qA,$CLJS.rF);$CLJS.Y(FK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.yD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.tD],null)],null)],null));
$CLJS.NF.g($CLJS.cF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.cF],null),FK,$CLJS.rj],null));$CLJS.OD.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.bE});$CLJS.tE($CLJS.cF,$CLJS.rF);$CLJS.AE($CLJS.LE,$CLJS.H([$CLJS.$r,$CLJS.ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.lD],null)],null)]));$CLJS.tE($CLJS.LE,$CLJS.rF);
$CLJS.AE($CLJS.iF,$CLJS.H([$CLJS.$r,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jD],null)],null)]));$CLJS.tE($CLJS.iF,$CLJS.rF);
$CLJS.Y($CLJS.rF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.QD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Fs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.is(", ",$CLJS.Cz($CLJS.Ed,$CLJS.xk.g($CLJS.q($CLJS.fB),$CLJS.rF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.AK(a)}],null)],null));