var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var LU,NU,OU,xma,SU,TU,UU,zma,VU,WU,XU,ZU,$U,aV,bV,cV,dV,fV,gV,hV,iV,jV,mV,nV,yma;LU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.dk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.he(c)}),a)};
$CLJS.MU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ne.g($CLJS.wB,function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.id(l,v);$CLJS.pe(t,$CLJS.KD(x,function(){return function(A){return $CLJS.Vj.l(LU(A),$CLJS.tA,$CLJS.H([$CLJS.ni]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),e($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.KD(u,function(){return function(v){return $CLJS.Vj.l(LU(v),$CLJS.tA,$CLJS.H([$CLJS.ni]))}}(u,k,f,b)),e($CLJS.Hc(k)))}return null}},null,null)}(a)}())};
NU=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.nd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.od(a);a=function(t,u,v,x,A,C,G,J){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(X,W,da,ra,Ma){return function(sb){var Qa=$CLJS.I(sb,0,null);sb=$CLJS.I(sb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yd.g(Ma,Qa),sb],null)}}(t,u,v,x,A,C,G,J)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Yd.g(c,l)):a=$CLJS.td(k)?a(k):$CLJS.sd(k)?a($CLJS.El($CLJS.yr,k)):m}else return c}};OU=function(a,b,c){return $CLJS.vd(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Dd(b,$CLJS.K.g(c,2))};$CLJS.PU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ck.g($CLJS.sD,$CLJS.dd)),$CLJS.cP.h(a));return NU($CLJS.Vj.l(a,$CLJS.HM,$CLJS.H([$CLJS.rU])),function(c){return OU($CLJS.qA,b,c)})};
$CLJS.QU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ck.g($CLJS.AD,$CLJS.dd)),$CLJS.cF.h(a));return NU($CLJS.Vj.l(a,$CLJS.HM,$CLJS.H([$CLJS.rU])),function(c){return OU($CLJS.cF,b,c)})};xma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.HM.h(d)]))}function c(d){return $CLJS.ae($CLJS.ZD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.sK.h(d)])))}return $CLJS.n($CLJS.RU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
SU=function(a){if($CLJS.sd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,J=$CLJS.zz(m,xma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(S,X,W,da,ra,Ma){return function Ja(Qa,Xa){try{if($CLJS.vd(Xa)&&3===$CLJS.D(Xa))try{var Va=$CLJS.F(Xa,0);if($CLJS.ce(Va,$CLJS.nF))try{var Ta=$CLJS.F(Xa,1);if($CLJS.n($CLJS.xO.h(Ta)))try{var mb=$CLJS.F(Xa,1);if(null!=mb?mb.C&256||$CLJS.yc===mb.mf||(mb.C?0:$CLJS.Ya($CLJS.ub,
mb)):$CLJS.Ya($CLJS.ub,mb))try{var dc=$CLJS.K.j(mb,$CLJS.xO,$CLJS.GU);if($CLJS.n($CLJS.Oe(da)(dc))){var ed=$CLJS.K.g(mb,$CLJS.xO);$CLJS.F(Xa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Ma),": no join named ",$CLJS.xh.l($CLJS.H([ed]))].join("")],null)}throw $CLJS.Z;}catch(hd){if(hd instanceof Error){var Jd=hd;if(Jd===$CLJS.Z)throw $CLJS.Z;throw Jd;}throw hd;}else throw $CLJS.Z;}catch(hd){if(hd instanceof Error){Jd=hd;if(Jd===$CLJS.Z)throw $CLJS.Z;throw Jd;
}throw hd;}else throw $CLJS.Z;}catch(hd){if(hd instanceof Error){Jd=hd;if(Jd===$CLJS.Z)throw $CLJS.Z;throw Jd;}throw hd;}else throw $CLJS.Z;}catch(hd){if(hd instanceof Error){Jd=hd;if(Jd===$CLJS.Z)throw $CLJS.Z;throw Jd;}throw hd;}else throw $CLJS.Z;}catch(hd){if(hd instanceof Error){Jd=hd;if(Jd===$CLJS.Z)return $CLJS.DU(Ja,Qa,Xa);throw Jd;}throw hd;}}}(f,k,l,J,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.Vj.l(C,$CLJS.HM,$CLJS.H([yma]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=J,l=G;
else return null}else return null};TU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);UU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);zma=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);VU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
WU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);XU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.YU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);ZU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
$U=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);aV=new $CLJS.r(null,"refs","refs",80480079,null);bV=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);cV=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);dV=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.eV=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);fV=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);gV=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);hV=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);iV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
jV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.kV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.RU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.lV=new $CLJS.M(null,"filters","filters",974726919);mV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);nV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.oV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);yma=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y(bV,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.eV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mP,$CLJS.Sa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.dl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pN,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.tD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JP,new $CLJS.h(null,1,[$CLJS.zr,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jU],null)],null)],null));$CLJS.Y(gV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rF],null));
$CLJS.Y(TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),gV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,"Breakouts must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.MU},UU,$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Dj,$CLJS.gi,$CLJS.zi,$CLJS.Qi,$CLJS.Mj,$CLJS.fi,$CLJS.yj,$CLJS.Cj],[$CLJS.vU,VU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[aV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.MU)?$CLJS.MU.H:null]))],null)],null));
$CLJS.Y(hV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,":fields must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.MU},UU,$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Dj,$CLJS.gi,$CLJS.zi,$CLJS.Qi,$CLJS.Mj,$CLJS.fi,$CLJS.yj,$CLJS.Cj],[$CLJS.vU,VU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[aV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.MU)?$CLJS.MU.H:null]))],null)],null));$CLJS.Y(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.LE],null),$CLJS.wj,$CLJS.rj],null)],null));
$CLJS.Y(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),iV],null));
var pV=$CLJS.zz(function(a){a=$CLJS.z($CLJS.PU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.K.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.QU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.K.g(a,1))].join("")}return null});$CLJS.Y(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,2,[$CLJS.us,"Valid references for a single query stage",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.bj);return pV(a)}],null),$CLJS.Oe(pV)],null));
$CLJS.Y($CLJS.oV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.YU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HM,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.qK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cP,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.bF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),TU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.PF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VD,new $CLJS.h(null,1,[$CLJS.zr,!0],null),hV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lV,new $CLJS.h(null,1,[$CLJS.zr,!0],null),fV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.yK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iO,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RU,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WJ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Dd(a,$CLJS.LP)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,
new $CLJS.h(null,1,[$CLJS.us,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.fD($CLJS.iO,$CLJS.RU))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jV],null)],null));$CLJS.Y(WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.PJ],null)],null)],null)],null));
$CLJS.Y(XU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WJ],null)],null)],null)],null));$CLJS.Y($U,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XU],null)],null));
$CLJS.Y(cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.zz($CLJS.iO,$CLJS.RU))],null)],null));$CLJS.Y(dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,$CLJS.eV,$CLJS.YU],null));
$CLJS.Y(zma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,dV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.vi,$CLJS.cj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oV],null)],null)],null)],null));
$CLJS.Y(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,dV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.vi,$CLJS.cj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$U],null)],null)],null)],null));$CLJS.Y(ZU,cV);
$CLJS.Y(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,2,[$CLJS.us,"Valid references for all query stages",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.bj);return SU.h?SU.h(a):SU.call(null,a)}],null),$CLJS.Oe(SU)],null));
$CLJS.Y($CLJS.oK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZU],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mV],null)],null));
$CLJS.Y($CLJS.OK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.kV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.XJ,$CLJS.eK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oK],null)],null)],null),$CLJS.pma],null));