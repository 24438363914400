var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var T3,V3,X3,Y3,Z3,$3,a4,Fra;T3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=T3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=T3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.U3=function(a,b){return T3($CLJS.e_(a),b)};
V3=function(a,b,c){var d=$CLJS.IV(a,b);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.cF);d=$CLJS.uG($CLJS.ck.j($CLJS.Tg([c]),$CLJS.AD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.hD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.ur,c,$CLJS.JE,a,$CLJS.IK,b],null));return d};$CLJS.W3=function(a){return $CLJS.K1($CLJS.zF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};X3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
Y3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);Z3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);$3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);a4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Fra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.R_.m(null,$CLJS.cF,function(a,b){var c=$CLJS.Be($CLJS.cF.h($CLJS.IV(a,b)));return $CLJS.n(c)?$CLJS.N1($CLJS.MD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.id(l,u);v=$CLJS.n_.v(a,b,v,$CLJS.o_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.n_.v(a,
b,t,$CLJS.o_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.U_.m(null,$CLJS.cF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.tA);e=$CLJS.K.g(e,$CLJS.ni);c=$CLJS.I(c,2,null);c=V3(a,b,c);return $CLJS.uk.l($CLJS.H([$CLJS.l_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.NJ,$CLJS.lK,$CLJS.F0,$CLJS.AD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ni,e],null):null]))});
$CLJS.O_.m(null,$CLJS.cF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.n_.v(a,b,V3(a,b,c),d)});$CLJS.tE(Z3,a4);
for(var b4=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.oF,$CLJS.wF],null)),c4=null,d4=0,e4=0;;)if(e4<d4){var Gra=c4.X(null,e4);$CLJS.tE(Gra,Z3);e4+=1}else{var f4=$CLJS.y(b4);if(f4){var g4=f4;if($CLJS.wd(g4)){var h4=$CLJS.ic(g4),Hra=$CLJS.jc(g4),Ira=h4,Jra=$CLJS.D(h4);b4=Hra;c4=Ira;d4=Jra}else{var Kra=$CLJS.z(g4);$CLJS.tE(Kra,Z3);b4=$CLJS.B(g4);c4=null;d4=0}e4=0}else break}
$CLJS.O_.m(null,Z3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.n_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.hD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.hD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.MD("Count");case "cum-count":return $CLJS.MD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.P_.m(null,Z3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.U_.m(null,$3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.nH($CLJS.U_,a4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.oj,$CLJS.SB)});
$CLJS.tE($3,a4);$CLJS.tE(Z3,$3);$CLJS.tE($CLJS.SE,$3);$CLJS.O_.m(null,$CLJS.WE,function(){return $CLJS.MD("Case")});$CLJS.P_.m(null,$CLJS.WE,function(){return"case"});$CLJS.tE(Y3,a4);
for(var i4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.hF,$CLJS.JF,$CLJS.SE,$CLJS.xj,$CLJS.AF,$CLJS.qi,$CLJS.CF,$CLJS.zF,$CLJS.TE],null)),j4=null,k4=0,l4=0;;)if(l4<k4){var Lra=j4.X(null,l4);$CLJS.tE(Lra,Y3);l4+=1}else{var m4=$CLJS.y(i4);if(m4){var n4=m4;if($CLJS.wd(n4)){var o4=$CLJS.ic(n4),Mra=$CLJS.jc(n4),Nra=o4,Ora=$CLJS.D(o4);i4=Mra;j4=Nra;k4=Ora}else{var Pra=$CLJS.z(n4);$CLJS.tE(Pra,Y3);i4=$CLJS.B(n4);j4=null;k4=0}l4=0}else break}
$CLJS.P_.m(null,Y3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.O_.m(null,Y3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.n_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.hD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.hD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.hD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.hD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.hD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.hD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.hD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.hD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.hD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.O_.m(null,$CLJS.ZE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.hD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.n_.v(a,b,e,d)]))});$CLJS.P_.m(null,$CLJS.ZE,function(){return"percentile"});
$CLJS.tE(X3,a4);for(var p4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.TE],null)),q4=null,r4=0,s4=0;;)if(s4<r4){var Qra=q4.X(null,s4);$CLJS.tE(Qra,X3);s4+=1}else{var t4=$CLJS.y(p4);if(t4){var u4=t4;if($CLJS.wd(u4)){var v4=$CLJS.ic(u4),Rra=$CLJS.jc(u4),Sra=v4,Tra=$CLJS.D(v4);p4=Rra;q4=Sra;r4=Tra}else{var Ura=$CLJS.z(u4);$CLJS.tE(Ura,X3);p4=$CLJS.B(u4);q4=null;r4=0}s4=0}else break}
$CLJS.U_.m(null,X3,function(a,b,c){var d=$CLJS.Vj.g,e=$CLJS.nH($CLJS.U_,a4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Vj,a,$CLJS.oj)});$CLJS.O_.m(null,$CLJS.HF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.hD("Sum of {0} matching condition",$CLJS.H([$CLJS.n_.v(a,b,e,d)]))});
$CLJS.P_.m(null,$CLJS.HF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.Q_.j(a,b,d))].join("")});$CLJS.tE($CLJS.HF,a4);$CLJS.O_.m(null,$CLJS.uF,function(){return $CLJS.MD("Share of rows matching condition")});$CLJS.P_.m(null,$CLJS.uF,function(){return"share"});$CLJS.U_.m(null,$CLJS.uF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.nH($CLJS.U_,a4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.oj,$CLJS.jC)});
$CLJS.tE($CLJS.uF,a4);$CLJS.O_.m(null,$CLJS.wF,function(){return $CLJS.MD("Count of rows matching condition")});$CLJS.U_.m(null,a4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.uk.l;d=$CLJS.n(d)?$CLJS.Fl($CLJS.l_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,$CLJS.oj],null)):null;var f=$CLJS.nH($CLJS.U_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.uk,$CLJS.H([d,a]))});
$CLJS.Vra=function(){function a(d){return $CLJS.K1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.K1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.rV.m(null,$CLJS.cF,function(a){return a});
$CLJS.w4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.WA(f),$CLJS.QJ))f=$CLJS.tV(f);else return $CLJS.P1(d,e,$CLJS.cF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.x4=function(){function a(d,e){return $CLJS.Be($CLJS.cF.h($CLJS.IV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.y4=function(){function a(d,e){var f=$CLJS.Be($CLJS.cF.h($CLJS.IV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.l_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.J1(l,$CLJS.ni,$CLJS.n(t)?t:$CLJS.$i),$CLJS.NJ,$CLJS.lK,$CLJS.H([$CLJS.F0,$CLJS.AD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.O_.m(null,$CLJS.VF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.RF);return $CLJS.oD.h(a.o?a.o():a.call(null))});$CLJS.W_.m(null,$CLJS.VF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.K.g(d,$CLJS.gF);b=$CLJS.K.g(d,$CLJS.RF);c=$CLJS.K.g(d,$CLJS.QF);d=$CLJS.K.g(d,$CLJS.PY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.M_,$CLJS.$z(a),$CLJS.H([$CLJS.K_,c]));return null!=d?$CLJS.R.j(a,$CLJS.v_,d):a});
$CLJS.Wra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.a1(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.IV(d,e),l=$CLJS.$_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ck.j($CLJS.ef(function(m){m=$CLJS.TF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.xl(function(m){m=$CLJS.O(m);var t=$CLJS.K.g(m,$CLJS.QF),u=$CLJS.K.g(m,$CLJS.OF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.Zh))return $CLJS.R.j(m,$CLJS.FV,l);t=$CLJS.Be($CLJS.A0(function(v){return $CLJS.EZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.FV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.cj,$CLJS.VF)})),$CLJS.WF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Xra=function(){function a(d,e){return $CLJS.sV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF.h(d),$CLJS.N,$CLJS.uV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.QF.h(d)))return $CLJS.sV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gF.h(d),$CLJS.N],null));var e=$CLJS.gF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Fra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();