var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var opa,ppa,qpa,rpa,spa,x0;
$CLJS.u0=function(){function a(e,f){if($CLJS.Ra(f))return"";e=$CLJS.yz(e);var k=$CLJS.mh.h(f);switch(k instanceof $CLJS.M?k.S:null){case "default":return $CLJS.BU("Default period","Default periods",e);case "millisecond":return $CLJS.BU("Millisecond","Milliseconds",e);case "second":return $CLJS.BU("Second","Seconds",e);case "minute":return $CLJS.BU("Minute","Minutes",e);case "hour":return $CLJS.BU("Hour","Hours",e);case "day":return $CLJS.BU("Day","Days",e);case "week":return $CLJS.BU("Week","Weeks",
e);case "month":return $CLJS.BU("Month","Months",e);case "quarter":return $CLJS.BU("Quarter","Quarters",e);case "year":return $CLJS.BU("Year","Years",e);case "minute-of-hour":return $CLJS.BU("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.BU("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.BU("Day of week","Days of week",e);case "day-of-month":return $CLJS.BU("Day of month","Days of month",e);case "day-of-year":return $CLJS.BU("Day of year","Days of year",e);case "week-of-year":return $CLJS.BU("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.BU("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.BU("Quarter of year","Quarters of year",e);default:return f=$CLJS.Os($CLJS.Xg(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.is(" ",$CLJS.ae($CLJS.vz(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.v0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.fB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.WA(f)},e,a,b,c,d)}();opa=$CLJS.Se($CLJS.N);ppa=$CLJS.Se($CLJS.N);
qpa=$CLJS.Se($CLJS.N);rpa=$CLJS.Se($CLJS.N);spa=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.fB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.w0=new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.WA,spa,opa,ppa,qpa,rpa);$CLJS.w0.m(null,$CLJS.Oh,function(){return null});$CLJS.w0.m(null,$CLJS.dG,function(a){return $CLJS.ei.h(a)});x0=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.XF,null,$CLJS.$F,null,$CLJS.aG,null,$CLJS.YF,null],null),null);
$CLJS.tpa=$CLJS.Wf.j($CLJS.xf,$CLJS.ck.g($CLJS.dk.h(x0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.cj,$CLJS.dG,$CLJS.ei,a],null);return $CLJS.E.g(a,$CLJS.Nk)?$CLJS.R.j(b,$CLJS.Oh,!0):b})),$CLJS.oG);$CLJS.upa=$CLJS.ek.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.cj,$CLJS.dG,$CLJS.ei,a],null);return $CLJS.E.g(a,$CLJS.Ok)?$CLJS.R.j(b,$CLJS.Oh,!0):b},$CLJS.jG);
$CLJS.vpa=$CLJS.Wf.j($CLJS.xf,$CLJS.ck.g($CLJS.dk.h(x0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.cj,$CLJS.dG,$CLJS.ei,a],null);return $CLJS.E.g(a,$CLJS.Ok)?$CLJS.R.j(b,$CLJS.Oh,!0):b})),$CLJS.pG);$CLJS.O_.m(null,$CLJS.dG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.K.g(a,$CLJS.ei);return $CLJS.u0.h(a)});
$CLJS.W_.m(null,$CLJS.dG,function(a,b,c){var d=$CLJS.uk.l;a=$CLJS.n_.j(a,b,c);b=$CLJS.$z($CLJS.w0.h(c));var e=$CLJS.w0.h(c);e=$CLJS.Dd($CLJS.rG,e)&&!$CLJS.Dd($CLJS.qG,e);return d.call($CLJS.uk,$CLJS.H([new $CLJS.h(null,3,[$CLJS.oD,a,$CLJS.M_,b,$CLJS.w_,e],null),$CLJS.Fl(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oh,$CLJS.v_],null))]))});
$CLJS.y0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.fB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();$CLJS.y0.m(null,$CLJS.Oh,function(){return $CLJS.Sg});
$CLJS.z0=function(){function a(d,e,f){return $CLJS.y0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();