var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var o7,p7,q7,ota,pta,qta,t7,u7,w7,x7,rta,sta,tta,z7,A7,B7,uta,vta;o7=function(a){var b=new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.sD,$CLJS.T)(a)],null)};p7=function(a){return $CLJS.R.j(a,$CLJS.cj,$CLJS.H4)};q7=function(a,b){return $CLJS.E.g($CLJS.nD.h(a),$CLJS.Fl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FD,$CLJS.mD,$CLJS.ED],null)))};
ota=function(a){return $CLJS.S0(a,new $CLJS.h(null,1,[$CLJS.FD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.uG(function(f){return $CLJS.E.g($CLJS.K.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OY,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.hD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.ek.g($CLJS.OY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(r7))return null;var d=r7;r7=!0;try{var e=$CLJS.M1(a,b),f=$CLJS.n(e)?$CLJS.IV(a,e):$CLJS.IV(a,b),k=function(){var m=$CLJS.s7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rU,$CLJS.FV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.RU.h(f);if($CLJS.n(u))return u;u=$CLJS.iO.h(f);if($CLJS.n(u))return u;u=$CLJS.cP.h(f);return $CLJS.n(u)?u:$CLJS.VD.h(f)}())?$CLJS.$_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.hD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.n3($CLJS.Vj.l(l,$CLJS.Fi,$CLJS.H([$CLJS.bK,$CLJS.RV,$CLJS.MV])),null),$CLJS.T,function(){var m=$CLJS.OY.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.NJ,$CLJS.AY):l:null}finally{r7=d}};
t7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.xO);var f=$CLJS.I(c,2,null);c=$CLJS.uk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ni,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ni,k],null):null}(),function(){var k=$CLJS.xQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.RV,k],null):null}(),function(){var k=$CLJS.tF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.MV,k],null):null}(),$CLJS.Bd(f)?function(){var k=$CLJS.S1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.cj,$CLJS.MJ,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.cj,$CLJS.MJ,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.cj,$CLJS.MJ,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.n3(c,d):c};
u7=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.MV);return $CLJS.n($CLJS.n(b)?$CLJS.Dd($CLJS.rG,b):b)?$CLJS.Wi:$CLJS.zz($CLJS.ni,$CLJS.tA)(a)};
$CLJS.v7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.K.g(f,$CLJS.AD),l=$CLJS.K.g(f,$CLJS.tA),m=$CLJS.K.g(f,$CLJS.xQ),t=$CLJS.K.g(f,$CLJS.ni);e=$CLJS.K.g(f,$CLJS.xO);var u=$CLJS.K.g(f,$CLJS.IM),v=$CLJS.K.g(f,$CLJS.tF),x=$CLJS.uk.l;k=new $CLJS.h(null,2,[$CLJS.cj,$CLJS.MJ,$CLJS.F0,k],null);f=$CLJS.oD.h(f);a=$CLJS.n(f)?f:$CLJS.n_.j(a,b,d);c=x.call($CLJS.uk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.oD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ni,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.MV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.RV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.aK,u):m;return $CLJS.n(e)?$CLJS.n3(u,e):u};w7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.ei.h(d),c)},a))?$CLJS.ek.g(function(d){var e=$CLJS.Dd(d,b)?$CLJS.Vj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ei.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
x7=function(a){var b=$CLJS.n($CLJS.t1.h(a))?null:function(){var e=$CLJS.NJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.QY,null,$CLJS.XY,null,$CLJS.AY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,u7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.aK.h(a)))?(f=$CLJS.bk.g($CLJS.KY,$CLJS.NJ.h(a)))?$CLJS.aZ.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.xO,e],null):null}(),function(){var e=$CLJS.m1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.xO,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tF,e],null):null}(),function(){var e=$CLJS.QV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.QV,e],null):null}(),function(){var e=$CLJS.RV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.xQ,e],null):null}(),function(){var e=$CLJS.aK.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.IM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.OY,$CLJS.T):$CLJS.zz($CLJS.Fi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.NJ.h(c),$CLJS.IY)},$CLJS.$_.v(a,b,$CLJS.IV(a,b),new $CLJS.h(null,3,[$CLJS.H_,!1,$CLJS.N_,!0,$CLJS.D_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.y7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);z7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);A7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.s7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);B7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.T0.m(null,$CLJS.nF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.S0(c,new $CLJS.h(null,2,[$CLJS.tF,$CLJS.mh,$CLJS.xQ,ota],null)),a],null)});
var r7=!1,C7=function C7(a,b){var d=$CLJS.b1(a,A7.h(b));a=$CLJS.n(A7.h(d))?C7.g?C7.g(a,d):C7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.K.g(a,$CLJS.T),f=$CLJS.K.g(a,$CLJS.oD);return $CLJS.fk.j($CLJS.R.j($CLJS.fk.j($CLJS.R.j(b,sta,$CLJS.T.h(b)),$CLJS.T,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),B7,$CLJS.oD.h(b)),$CLJS.oD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.S_.m(null,$CLJS.MJ,function(a,b,c){return u7(c)});
$CLJS.S_.m(null,$CLJS.nF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.tF);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.MV,d):c;return $CLJS.T_.j(a,b,c)});$CLJS.U_.m(null,$CLJS.MJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.U_.m(null,$CLJS.nF,function(a,b,c){var d=t7(a,b,c);b=$CLJS.v7(a,b,d,c);return $CLJS.n(A7.h(b))?C7(a,b):b});
$CLJS.O_.m(null,$CLJS.MJ,function(a,b,c,d){function e(W){var da=$CLJS.n5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",W,da):$CLJS.ha.call(null,"%s: %s",W,da)}function f(W){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",W,da):$CLJS.ha.call(null,"%s: %s",W,da)}var k=$CLJS.O(c),l=$CLJS.K.g(k,$CLJS.RV),m=$CLJS.K.g(k,$CLJS.aK),t=$CLJS.K.g(k,$CLJS.bK),u=$CLJS.K.g(k,$CLJS.ei),v=$CLJS.K.g(k,$CLJS.aZ),x=$CLJS.K.g(k,B7),A=$CLJS.K.g(k,$CLJS.T),C=$CLJS.K.g(k,$CLJS.N5),G=$CLJS.K.g(k,
$CLJS.oD),J=$CLJS.K.g(k,A7),S=$CLJS.k1.g($CLJS.j1,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(J)?null==G||$CLJS.E.g(G,S):J)){a:{var W=$CLJS.O(k);var da=$CLJS.K.g(W,$CLJS.oD);W=$CLJS.K.g(W,A7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(W)){W=$CLJS.b1(a,W);var ra=$CLJS.O(W);W=$CLJS.K.g(ra,$CLJS.oD);ra=$CLJS.K.g(ra,A7);da=$CLJS.Yd.g(da,W);W=ra}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.is(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.o_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var W=$CLJS.b1(a,m);$CLJS.n(W)?W=$CLJS.j3($CLJS.oD.h($CLJS.q_.j(a,b,W))):(W=$CLJS.l1(a,t),W=$CLJS.n_.v(a,b,W,d))}else W=null;return $CLJS.n(W)?W:$CLJS.n(v)?v:$CLJS.m1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var W=$CLJS.bk.g(d,$CLJS.o_);return W?C:W}())?c:$CLJS.n($CLJS.n(u)?$CLJS.bk.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.bk.g(c,e(S)):l)?e(c):c});
$CLJS.O_.m(null,$CLJS.nF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.K.g(f,$CLJS.xQ);var k=$CLJS.K.g(f,$CLJS.xO),l=$CLJS.K.g(f,$CLJS.tF);f=$CLJS.K.g(f,$CLJS.IM);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.aZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ei,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.RV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.aK,f):e;return $CLJS.n(e)?$CLJS.n_.v(a,b,e,d):$CLJS.MD("[Unknown Field]")});
$CLJS.P_.m(null,$CLJS.MJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.K.g(a,$CLJS.T)});$CLJS.P_.m(null,$CLJS.nF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=t7(a,b,c);return $CLJS.n(c)?$CLJS.Q_.j(a,b,c):"unknown_field"});
$CLJS.W_.m(null,$CLJS.MJ,function(a,b,c){return $CLJS.uk.l($CLJS.H([function(){var d=$CLJS.nH($CLJS.W_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.n_.j(a,b,c),$CLJS.p_,$CLJS.n_.v(a,b,c,$CLJS.o_)],null),$CLJS.E.g($CLJS.NJ.h(c),$CLJS.QY)?function(){var d=$CLJS.$Y.h(c);return $CLJS.n(d)?(d=$CLJS.h_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Hj,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.oD,$CLJS.T.h(d)],null)],null):null):null}():null]))});
$CLJS.w0.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.tF.h(b)});$CLJS.w0.m(null,$CLJS.MJ,function(a){return $CLJS.MV.h(a)});
$CLJS.v0.m(null,$CLJS.nF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Dd($CLJS.rG,b),e=$CLJS.pB($CLJS.QV,$CLJS.ni,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.tF,b,$CLJS.H([$CLJS.ni,d?$CLJS.Wi:e,$CLJS.QV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,a],null)}b=$CLJS.QV.h(c);c=$CLJS.n(b)?$CLJS.Vj.g($CLJS.R.j(c,$CLJS.ni,b),$CLJS.QV):c;c=$CLJS.Vj.g(c,$CLJS.tF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,c,a],null)});
$CLJS.v0.m(null,$CLJS.MJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.MV,b,$CLJS.H([$CLJS.QV,$CLJS.pB($CLJS.QV,$CLJS.ni,$CLJS.tA)(a)])):$CLJS.Vj.l(a,$CLJS.MV,$CLJS.H([$CLJS.QV]))});$CLJS.y0.m(null,$CLJS.nF,function(a,b,c){return $CLJS.z0.j(a,b,t7(a,b,c))});
$CLJS.y0.m(null,$CLJS.MJ,function(a,b,c){if($CLJS.bk.g($CLJS.NJ.h(c),$CLJS.IY)){a=$CLJS.zz($CLJS.ni,$CLJS.tA)(c);b=null==c?null:$CLJS.WM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Hk.h($CLJS.Pi.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.K.g(f,uta),l=$CLJS.K.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.t0.h(k),$CLJS.t0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Eq.g?$CLJS.Eq.g(1,m):$CLJS.Eq.call(null,1,m))?$CLJS.Ek:$CLJS.n($CLJS.Eq.g?$CLJS.Eq.g(31,m):$CLJS.Eq.call(null,31,m))?$CLJS.Ok:$CLJS.n($CLJS.Eq.g?
$CLJS.Eq.g(365,m):$CLJS.Eq.call(null,365,m))?$CLJS.Ri:$CLJS.Xi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Hk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Ak)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Pk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?w7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.MV.h(c))?w7(d,$CLJS.v_,$CLJS.MV.h(c)):d}return $CLJS.xf});
$CLJS.k5.m(null,$CLJS.nF,function(a){var b=null==a?null:$CLJS.ID(a);b=null==b?null:$CLJS.xQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.cj,$CLJS.p5,$CLJS.H([$CLJS.o5,function(c,d){return t7(c,d,a)}]))});$CLJS.k5.m(null,$CLJS.MJ,function(a){var b=null==a?null:$CLJS.RV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.cj,$CLJS.p5,$CLJS.H([$CLJS.o5,$CLJS.Pe(a)]))});$CLJS.q5.m(null,$CLJS.nF,function(a,b){return $CLJS.JD(a,$CLJS.HD,$CLJS.H([$CLJS.xQ,b]))});
$CLJS.q5.m(null,$CLJS.MJ,function(a,b){return $CLJS.HD(a,$CLJS.RV,b)});$CLJS.r5.m(null,$CLJS.nF,function(a,b,c){return $CLJS.s5.j(a,b,t7(a,b,c))});
$CLJS.r5.m(null,$CLJS.MJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.K.g(b,$CLJS.ni);var d=$CLJS.K.g(b,$CLJS.WM),e=$CLJS.K.g(b,$CLJS.oj);if($CLJS.bk.g($CLJS.NJ.h(b),$CLJS.IY)){var f=function(){var m=null==a?null:$CLJS.a1(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Dd(m,$CLJS.xQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.kj],null)),l=$CLJS.l5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.wd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var J=0;;)if(J<A){var S=$CLJS.id(x,J),X=C,W=S;S=q7(S,l)?$CLJS.R.j(W,$CLJS.v_,!0):W;X.add(S);J+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var J=G;return q7(G,l)?$CLJS.R.j(J,$CLJS.v_,!0):J}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.qi.h(k);return $CLJS.n(t)?$CLJS.xj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.Yi)?$CLJS.ek.g(p7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.m5(),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 0.1 degrees"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,.1],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 1 degree"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,1],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 10 degrees"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,10],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("Bin every 20 degrees"),$CLJS.nD,new $CLJS.h(null,
2,[$CLJS.FD,$CLJS.ED,$CLJS.ED,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.kj)&&!$CLJS.Dz(e,$CLJS.ai)?$CLJS.ek.g(p7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.m5(),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("10 bins"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.mD,$CLJS.mD,10],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("50 bins"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.mD,$CLJS.mD,50],null)],null),new $CLJS.h(null,2,[$CLJS.oD,$CLJS.MD("100 bins"),$CLJS.nD,new $CLJS.h(null,2,[$CLJS.FD,$CLJS.mD,$CLJS.mD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.rV.m(null,$CLJS.nF,function(a){return a});
$CLJS.rV.m(null,$CLJS.MJ,function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.NJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.AD,$CLJS.p.h($CLJS.LD()),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a),$CLJS.CK,$CLJS.T.h(a)],null),a=$CLJS.F0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,b,a],null);case "source/expressions":return o7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.sD.h(a))?o7(a):x7(a);default:return x7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.ek.g($CLJS.tV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.xl(function(t){return $CLJS.Y1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.dk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.tV),m):null;return $CLJS.TV.l(e,f,$CLJS.HD,$CLJS.H([$CLJS.VD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.D7=function(){function a(d,e){return $CLJS.VD.h($CLJS.IV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.$_.v(d,e,$CLJS.IV(d,e),new $CLJS.h(null,3,[$CLJS.H_,!1,$CLJS.N_,!1,$CLJS.D_,!1],null)),k=$CLJS.D7.g(d,e);return $CLJS.pd(k)?$CLJS.ek.g(function(l){return $CLJS.R.j(l,$CLJS.PY,!0)},f):$CLJS.l3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(z7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,$CLJS.aj,$CLJS.DY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,z7],null)],null)],null));