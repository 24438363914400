var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.l1=function(a,b){var c=$CLJS.JV(b);return $CLJS.n(c)?$CLJS.h_(a,c):$CLJS.f_(a,b)};$CLJS.m1=function(a){var b=$CLJS.WA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.xO.h($CLJS.ID(a));case "metadata/column":return $CLJS.gZ.h(a);case "mbql/join":return $CLJS.ZD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.n1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.o1=function(a,b){var c=$CLJS.m1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.K.g(d,$CLJS.aK);d=$CLJS.K.g(d,$CLJS.bK);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.l1(a,d),$CLJS.n(d))){d=$CLJS.T.h(d);a=$CLJS.T.h($CLJS.b1(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.n1(a,$CLJS.T.h(b)):$CLJS.T.h(b)};
$CLJS.p1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.bK),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.ck.l($CLJS.ef($CLJS.YJ),$CLJS.ef($CLJS.Fi),$CLJS.ef($CLJS.ck.g($CLJS.bl,$CLJS.Fi)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.Fi),l=$CLJS.K.g(f,$CLJS.YJ);return $CLJS.R.l($CLJS.b1(a,l),$CLJS.E_,k,$CLJS.H([$CLJS.F_,$CLJS.gZ.h(f)]))}),$CLJS.dk.h(function(f){return $CLJS.Dd(e,$CLJS.bK.h(f))}),$CLJS.gD(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.bK),l=$CLJS.K.g(f,
$CLJS.E_),m=$CLJS.K.g(f,$CLJS.F_);f=$CLJS.f_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var W=$CLJS.id(A,X),da=$CLJS.R.l(W,$CLJS.aK,l,$CLJS.H([$CLJS.B_,m,$CLJS.NJ,$CLJS.KY,$CLJS.zY,$CLJS.T.h(W)]));W=G;var ra=$CLJS.R,Ma=ra.j,sb=da;da=$CLJS.o1(a,da);da=d.h?d.h(da):d.call(null,da);ra=Ma.call(ra,sb,$CLJS.OY,da);W.add(ra);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var J=$CLJS.z(x),S=$CLJS.R.l(J,$CLJS.aK,l,$CLJS.H([$CLJS.B_,m,$CLJS.NJ,$CLJS.KY,$CLJS.zY,$CLJS.T.h(J)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.OY,function(){var X=$CLJS.o1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.Z_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.J_,d,$CLJS.D_,!1],null)))})])),c)};$CLJS.q1=function(a,b){return $CLJS.g1.g(a,b)};$CLJS.r1=function(a){return $CLJS.hD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.s1=function(a,b){var c=$CLJS.VD.h(b);$CLJS.n(c)||(c=$CLJS.TY.h(b),$CLJS.n(c)||(c=$CLJS.SY.h(b),c=null!=c?$CLJS.Y_.h($CLJS.q1(a,$CLJS.aY.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.td(c)?$CLJS.FV.h(c):$CLJS.sd(c)?c:null),$CLJS.n(c)?$CLJS.ek.g($CLJS.Xs(Spa,a,b),c):null):null};$CLJS.t1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.u1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.O_.m(null,$CLJS.jK,function(a,b,c){return $CLJS.zz($CLJS.oD,$CLJS.T)(c)});$CLJS.U_.m(null,$CLJS.jK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);c=$CLJS.K.g(a,$CLJS.oD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.oD,$CLJS.k1.g($CLJS.j1,b)):a});
$CLJS.Z_.m(null,$CLJS.jK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.K.g(c,$CLJS.VD),f=$CLJS.K.g(c,$CLJS.TY);d=$CLJS.O(d);var k=$CLJS.K.g(d,$CLJS.D_),l=$CLJS.K.g(d,$CLJS.J_);return $CLJS.bf.g($CLJS.Y_.v(a,b,c,d),$CLJS.n(k)?$CLJS.p1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.R_.m(null,$CLJS.RU,function(a,b){var c=$CLJS.IV(a,b);c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.RU);if($CLJS.n(c)){var d=$CLJS.h_(a,c);a=$CLJS.n(d)?$CLJS.n_.v(a,b,d,$CLJS.o_):null;return $CLJS.n(a)?a:$CLJS.r1(c)}return null});
var Spa=function(){function a(d,e,f){var k=$CLJS.Vj.g($CLJS.Ez(f,$CLJS.eA),$CLJS.u1);f=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.$i,$CLJS.cj,$CLJS.MJ],null),function(){var l=$CLJS.Fi.h(k);if($CLJS.n(l))try{return $CLJS.b1(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.cj,$CLJS.MJ,$CLJS.NJ,$CLJS.QY,$CLJS.zY,$CLJS.zz($CLJS.zY,$CLJS.T)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.$Y,$CLJS.Y0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.GY.h($CLJS.h_(d,
$CLJS.Y0(e)))):!0)?$CLJS.R.j(f,$CLJS.t1,!0):f;return $CLJS.bk.g($CLJS.oj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.YJ,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.X_.m(null,$CLJS.jK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.K.g(b,$CLJS.J_);return $CLJS.ek.g(function(f){var k=$CLJS.pB($CLJS.OY,$CLJS.zY,$CLJS.T)(f);return $CLJS.R.j(f,$CLJS.OY,e.h?e.h(k):e.call(null,k))},$CLJS.s1(a,c))});